/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MarketingText interface.
 */
export function instanceOfMarketingText(value) {
    if (!('agency' in value) || value['agency'] === undefined)
        return false;
    return true;
}
export function MarketingTextFromJSON(json) {
    return MarketingTextFromJSONTyped(json, false);
}
export function MarketingTextFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'textDe': json['textDe'] == null ? undefined : json['textDe'],
        'textFr': json['textFr'] == null ? undefined : json['textFr'],
        'textIt': json['textIt'] == null ? undefined : json['textIt'],
        'textEn': json['textEn'] == null ? undefined : json['textEn'],
        'agency': json['agency'],
        'trains': json['trains'] == null ? undefined : json['trains'],
    };
}
export function MarketingTextToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'textDe': value['textDe'],
        'textFr': value['textFr'],
        'textIt': value['textIt'],
        'textEn': value['textEn'],
        'agency': value['agency'],
        'trains': value['trains'],
    };
}
