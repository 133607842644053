/* tslint:disable */
/* eslint-disable */
/**
 * PIDS Feed API
 * PIDS Feed API
 *
 * The version of the OpenAPI document: 1.5.13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const FeedStatusLevelEnum = {
    Info: 'INFO',
    Warn: 'WARN',
    Error: 'ERROR'
};
/**
 * Check if a given object implements the FeedStatus interface.
 */
export function instanceOfFeedStatus(value) {
    if (!('message' in value) || value['message'] === undefined)
        return false;
    if (!('level' in value) || value['level'] === undefined)
        return false;
    return true;
}
export function FeedStatusFromJSON(json) {
    return FeedStatusFromJSONTyped(json, false);
}
export function FeedStatusFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'message': json['message'],
        'level': json['level'],
    };
}
export function FeedStatusToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'message': value['message'],
        'level': value['level'],
    };
}
