/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TransportGroup = {
    LocalTraffic: 'LOCAL_TRAFFIC',
    LongDistanceTraffic: 'LONG_DISTANCE_TRAFFIC',
    ShortDistanceTraffic: 'SHORT_DISTANCE_TRAFFIC'
};
export function instanceOfTransportGroup(value) {
    for (const key in TransportGroup) {
        if (Object.prototype.hasOwnProperty.call(TransportGroup, key)) {
            if (TransportGroup[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function TransportGroupFromJSON(json) {
    return TransportGroupFromJSONTyped(json, false);
}
export function TransportGroupFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TransportGroupToJSON(value) {
    return value;
}
