/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateRangeFromJSON, DateRangeToJSON, } from './DateRange';
/**
 * @export
 */
export const ScheduleTypeEnum = {
    CompleteSchedule: 'COMPLETE_SCHEDULE',
    DailySchedule: 'DAILY_SCHEDULE'
};
/**
 * @export
 */
export const ScheduleProtocolEnum = {
    Hrdf: 'HRDF',
    Vdv454Aus: 'VDV_454_AUS',
    Vdv736: 'VDV_736',
    GtfsStatic: 'GTFS_STATIC',
    GtfsRealtime: 'GTFS_REALTIME'
};
/**
 * Check if a given object implements the Schedule interface.
 */
export function instanceOfSchedule(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('type' in value) || value['type'] === undefined)
        return false;
    return true;
}
export function ScheduleFromJSON(json) {
    return ScheduleFromJSONTyped(json, false);
}
export function ScheduleFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'type': json['type'],
        'agencies': json['agencies'] == null ? undefined : json['agencies'],
        'protocol': json['protocol'] == null ? undefined : json['protocol'],
        'validity': json['validity'] == null ? undefined : DateRangeFromJSON(json['validity']),
    };
}
export function ScheduleToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'type': value['type'],
        'agencies': value['agencies'],
        'protocol': value['protocol'],
        'validity': DateRangeToJSON(value['validity']),
    };
}
