import { derived, writable } from 'svelte/store';
import { KeycloakService } from './keycloakService';
import { registerLocale } from 'src/config/i18n';
import { DEFAULT_USER, DEFAULT_USER_LOCALE } from './user';
import { de, fr, enUS, it } from 'date-fns/locale';
import { Language } from '$generated/service/cache-api';
export const internalUserStore = writable(DEFAULT_USER);
export const user = derived(internalUserStore, value => value);
user.subscribe($user => {
    console.info('User store updated: ', $user);
    registerLocale($user.locale);
});
export const userLoggedIn = derived(user, $user => $user && $user.username !== undefined && $user !== DEFAULT_USER);
export const userLocale = derived(user, $user => { var _a; return (_a = $user === null || $user === void 0 ? void 0 : $user.locale) !== null && _a !== void 0 ? _a : DEFAULT_USER_LOCALE; });
export const userDateFnsLocale = derived(userLocale, $userLocaleStore => getLocaleObject($userLocaleStore));
export const userLanguage = derived(userLocale, $userLocaleStore => getLanguageObject($userLocaleStore));
export const userOrganization = derived(user, $userStore => $userStore ? $userStore.organization : '');
export const login = (newUser) => {
    console.info('UserStore.login()', newUser);
    internalUserStore.set(newUser);
};
export const updateToken = (newToken) => {
    internalUserStore.update(prev => (Object.assign(Object.assign({}, prev), { token: newToken })));
};
export const logout = () => {
    console.log('UserStore.logout()');
    KeycloakService.getKeycloakInstance().logout();
};
const getLocaleObject = (language) => {
    switch (language.toLowerCase()) {
        case 'de':
            return de;
        case 'fr':
            return fr;
        case 'it':
            return it;
        case 'en':
            return enUS;
        default:
            return de;
    }
};
const getLanguageObject = (language) => {
    switch (language.toLowerCase()) {
        case 'de':
            return Language.De;
        case 'fr':
            return Language.Fr;
        case 'it':
            return Language.It;
        case 'en':
            return Language.En;
        default:
            return Language.De;
    }
};
