import { get } from 'svelte/store';
import { locale, addMessages } from 'svelte-i18n';
import enJson from 'public/i18n/lang.json';
import deJson from 'public/i18n/lang_de.json';
import frJson from 'public/i18n/lang_fr.json';
//adding locale json files to dictionary
addMessages('en', enJson);
addMessages('de', deJson);
addMessages('fr', frJson);
export const registerLocale = (userLocale) => {
    if (get(locale) === userLocale) {
        return;
    }
    locale.set(userLocale);
};
