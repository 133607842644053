/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { DependenciesFromJSON, } from '../models/index';
/**
 *
 */
export class LicenseApi extends runtime.BaseAPI {
    /**
     * Request all the third party software dependencies and their licenses
     */
    getLicenseInformationRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/license`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DependenciesFromJSON(jsonValue));
        });
    }
    /**
     * Request all the third party software dependencies and their licenses
     */
    getLicenseInformation(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getLicenseInformationRaw(initOverrides);
            return yield response.value();
        });
    }
}
