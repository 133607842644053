/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaginationFromJSON, PaginationToJSON, } from './Pagination';
/**
 * Check if a given object implements the ResponseMetadata interface.
 */
export function instanceOfResponseMetadata(value) {
    if (!('pagination' in value) || value['pagination'] === undefined)
        return false;
    return true;
}
export function ResponseMetadataFromJSON(json) {
    return ResponseMetadataFromJSONTyped(json, false);
}
export function ResponseMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'pagination': PaginationFromJSON(json['pagination']),
    };
}
export function ResponseMetadataToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'pagination': PaginationToJSON(value['pagination']),
    };
}
