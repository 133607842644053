/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StationFromJSON, StationToJSON, } from './Station';
import { ResponseMetadataFromJSON, ResponseMetadataToJSON, } from './ResponseMetadata';
/**
 * Check if a given object implements the Stations interface.
 */
export function instanceOfStations(value) {
    if (!('metadata' in value) || value['metadata'] === undefined)
        return false;
    if (!('results' in value) || value['results'] === undefined)
        return false;
    return true;
}
export function StationsFromJSON(json) {
    return StationsFromJSONTyped(json, false);
}
export function StationsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'metadata': ResponseMetadataFromJSON(json['metadata']),
        'results': (json['results'].map(StationFromJSON)),
    };
}
export function StationsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'metadata': ResponseMetadataToJSON(value['metadata']),
        'results': (value['results'].map(StationToJSON)),
    };
}
