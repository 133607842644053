/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccessibilityPlatformAccessFromJSON, AccessibilityPlatformAccessToJSON, } from './AccessibilityPlatformAccess';
/**
 * Check if a given object implements the Platform interface.
 */
export function instanceOfPlatform(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    return true;
}
export function PlatformFromJSON(json) {
    return PlatformFromJSONTyped(json, false);
}
export function PlatformFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'accessibilityPlatformAccess': json['accessibilityPlatformAccess'] == null ? undefined : AccessibilityPlatformAccessFromJSON(json['accessibilityPlatformAccess']),
        'sectors': json['sectors'] == null ? undefined : json['sectors'],
    };
}
export function PlatformToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'accessibilityPlatformAccess': AccessibilityPlatformAccessToJSON(value['accessibilityPlatformAccess']),
        'sectors': value['sectors'],
    };
}
