<script lang="ts">
  import { _ } from 'svelte-i18n';
  import type { LineCategoriesFilterCriteria } from 'src/components/pages/linecategories/lineCategoriesService';
  import { DebouncedTextInput, Icon, DropdownSelect, defaultStringIncludesFilter } from '@pids/shared-component';
  import { VehicleCategory, TransportGroup } from '$generated/service/cache-api';

  const categories: string[] = Object.values(VehicleCategory);
  const transportGroups: string[] = Object.values(TransportGroup);

  export let filter: LineCategoriesFilterCriteria;
</script>

<div class="columns">
  <div class="column">
    <label for="transport-type-search">
      {$_('line-categories.filter.transport-type.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="transport-type-search"
        class="input"
        placeholder={$_('line-categories.filter.transport-type.placeholder')}
        bind:value={filter.transportType}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    <label for="category-filter">
      {$_('line-categories.filter.category.label')}
    </label>
    <div class="control">
      <DropdownSelect
        id="category-filter"
        items={categories}
        bind:value={filter.category}
        placeholder={$_('line-categories.filter.category.placeholder')}
        readonly={Boolean(filter.category)}
        filter={defaultStringIncludesFilter} />
    </div>
  </div>
  <div class="column">
    <label for="transport-group-filter">
      {$_('line-categories.filter.transport-group.label')}
    </label>
    <div class="control">
      <DropdownSelect
        id="transport-group-filter"
        items={transportGroups}
        bind:value={filter.transportGroup}
        placeholder={$_('line-categories.filter.transport-group.placeholder')}
        readonly={Boolean(filter.transportGroup)}
        filter={defaultStringIncludesFilter} />
    </div>
  </div>
</div>
