import { __awaiter } from "tslib";
import Keycloak from 'keycloak-js';
import { DEFAULT_ORGANIZATION_ROLES, DEFAULT_USER_LOCALE, DEFAULT_USER_ROLES } from 'src/user/user';
let keycloakInstance;
const initKeycloakAndGetUser = (keycloakConfigFileUrl, onTokenRefreshed) => __awaiter(void 0, void 0, void 0, function* () {
    console.info('initKeycloakAndGetUser', keycloakConfigFileUrl);
    const authenticated = yield initKeycloak(keycloakConfigFileUrl, onTokenRefreshed);
    if (!authenticated) {
        throw new Error('Not authenticated in Keycloak');
    }
    console.info('User authenticated: ', authenticated);
    const kcInstance = getKeycloakInstance();
    return loadUserFromKeycloak(kcInstance);
});
const getKeycloakInstance = () => {
    console.info('getKeycloakInstance()');
    if (keycloakInstance === undefined) {
        throw new Error('Keycloak is not initialized yet');
    }
    return keycloakInstance;
};
const initKeycloak = (keycloakConfigFileUrl, onTokenRefreshed) => {
    console.debug('Initializing Keycloak');
    keycloakInstance = new Keycloak(keycloakConfigFileUrl);
    keycloakInstance.onTokenExpired = () => {
        console.info('Token expired');
        keycloakInstance
            .updateToken(60)
            .then(refreshed => {
            if (refreshed) {
                console.info('Token was successfully refreshed');
                const newToken = keycloakInstance.token;
                if (newToken) {
                    onTokenRefreshed(newToken);
                }
            }
            else {
                console.info('Token is still valid');
            }
        })
            .catch(err => {
            const errorMessage = 'Failed to refresh the token, or the session has expired';
            console.error(errorMessage, err);
            keycloakInstance.clearToken();
            keycloakInstance.logout();
        });
    };
    return keycloakInstance.init({
        // keycloak options
        onLoad: 'login-required'
    });
};
function loadUserFromKeycloak(keycloak) {
    var _a;
    console.debug('Keycloak subject', keycloak.subject);
    console.debug('Keycloak object', keycloak);
    const userId = (_a = keycloak.subject) !== null && _a !== void 0 ? _a : '';
    const idTokenParsed = keycloak.idTokenParsed;
    const username = idTokenParsed.preferred_username;
    const userEmail = idTokenParsed.email;
    const userFirstName = idTokenParsed.given_name;
    const userLastName = idTokenParsed.family_name;
    const userLocale = idTokenParsed.locale;
    const organization = idTokenParsed.organization;
    const tokenParsed = keycloak.tokenParsed;
    const token = keycloak.token;
    const keycloakRoles = tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.realm_access;
    const userRoles = keycloakRoles === null || keycloakRoles === void 0 ? void 0 : keycloakRoles.roles;
    const userRolesSet = new Set(userRoles ? [...userRoles] : DEFAULT_USER_ROLES);
    const userRolesToUse = Array.from(userRolesSet);
    console.debug('User roles', userRolesToUse);
    const organizationRoles = extractOrganizationRoles(tokenParsed);
    console.debug('Organization roles', organizationRoles);
    return {
        id: userId,
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
        locale: userLocale ? userLocale : DEFAULT_USER_LOCALE,
        organizationRoles,
        username: username,
        roles: userRolesToUse,
        organization: organization,
        token: token
    };
}
function extractOrganizationRoles(tokenParsed) {
    const client = tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.azp;
    const keycloakResourceAccess = tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.resource_access;
    const keycloakRoles = client ? keycloakResourceAccess === null || keycloakResourceAccess === void 0 ? void 0 : keycloakResourceAccess[client] : undefined;
    const roles = keycloakRoles === null || keycloakRoles === void 0 ? void 0 : keycloakRoles.roles;
    const rolesSet = new Set(roles ? [...roles] : DEFAULT_ORGANIZATION_ROLES);
    return Array.from(rolesSet);
}
export const KeycloakService = { initKeycloakAndGetUser, getKeycloakInstance };
